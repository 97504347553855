import { Flex, Avatar, Box, Text, Button, Stack } from "@chakra-ui/react";

interface IChatScreenHeaderProps {
  name?: string;
  avatar?: string;
}

export default function ChatScreenHeader({
  name,
  avatar,
}: IChatScreenHeaderProps) {
  return (
    <Flex
      position="absolute"
      zIndex="sticky"
      alignItems="center"
      h="64px"
      w="full"
      bg="gray.100"
      borderBottom="1px solid"
      borderColor="gray.300"
    >
      <Flex alignItems="center" w="full" h="full" px="8">
        <Avatar mr="4" w="40px" h="40px" name={name} src={avatar} />
        <Box w="full" display="flex" justifyContent="space-between" alignItems="center">
          <Text
            fontSize="md"
            fontWeight="semibold"
            color="gray.600"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {name}
          </Text>
          <Stack direction="row">
            <Button size="sm" colorScheme="green">Transferir</Button>
            <Button size="sm" colorScheme="red">Encerrar</Button>
          </Stack>

        </Box>
      </Flex>
    </Flex>
  );
}
