import { chakra, Box, Image, Flex, useColorModeValue } from "@chakra-ui/react";
import illustration from "assets/undraw_group_chat.svg";

export default function EmptyStateScreen() {
  return (
    <Box px={4} py={32} mx="auto">
      <Flex w="full" justifyContent="center" alignItems="center" py="4">
        <Image src={illustration} alt="Connect with your clients" width="56" />
      </Flex>
      <Box
        w={{ base: "full", md: 11 / 12, xl: 8 / 12 }}
        textAlign={{ base: "left", md: "center" }}
        mx="auto"
      >
        <chakra.h1
          mb={3}
          fontSize={{ base: "4xl", md: "5xl" }}
          fontWeight={{ base: "bold", md: "extrabold" }}
          color={useColorModeValue("gray.900", "gray.100")}
          lineHeight="shorter"
        >
          Connect with your clients.
        </chakra.h1>
        <chakra.p
          mb={6}
          fontSize={{ base: "lg", md: "xl" }}
          color="gray.500"
          lineHeight="base"
        >
          Send and receive messages on our chat omnichannel.
        </chakra.p>
      </Box>
    </Box>
  );
}
