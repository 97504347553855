import { Stack } from "@chakra-ui/react";
import { ReactNode } from "react";

interface IChatListProps {
  children?: ReactNode;
}

export default function ChatList({ children }: IChatListProps) {
  return (
    <Stack
      direction="column"
      spacing="0"
      h="full"
      w="full"
      overflowX="hidden"
      overflowY="auto"
    >
      {children}
    </Stack>
  );
}
