import { Box, Flex, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

interface MessageBoxProps {
  children: ReactNode;
  type?: string
  isMine?: boolean;
  sender: string;
}

const isUserAdmin = true;

function MessageBox({ children, type, isMine, sender }: MessageBoxProps) {
  const senderName = () => {
    if (isMine) {
      return "Eu";
    }
    
    return isUserAdmin ? sender.split("@")[0] : 'Outro atendente'
  }

  return (
    <Flex
      flex="0 0 70%"
      maxW="70%"
      ml={type !== 'client' ? "30%" : ""}
      justifyContent={type !== 'client' ? "flex-end" : "flex-start"}
    >
      <Box
        boxSizing="content-box"
        position="relative"
        px="4"
        py="2"
        alignItems="end"
        minW="100px"
      >
        {
          type === 'client' ? 
          (<Box
            bg={"white"}
            borderRadius="md"
            boxShadow="sm"
            p="8px 9px 3px"
            maxW="full"
            fontSize="sm"
            whiteSpace="pre-line"
            willChange="box-shadow"
          >
            {children}
          </Box>
          ) : (
            <Box
            bg={isMine ? "#DEF7CD" : "#ffeaa7"}
            borderRadius="md"
            boxShadow="sm"
            p="0.4em 0.75em"
            maxW="full"
            fontSize="sm"
            whiteSpace="pre-line"
            willChange="box-shadow"
          >
            <Text my={'2px'} fontWeight={'semibold'} fontSize={"0.90em"}>
              {senderName()}
            </Text>
            {children}
          </Box>
          )
        }
      </Box>
    </Flex>
  );
}

export default MessageBox;