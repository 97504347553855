import { Badge, Button } from "@chakra-ui/react";
import { FiChevronDown } from "react-icons/fi";

interface ButtonScrollBottomProps {
  bottom?: number;
  onClick: () => void;
}

const ButtonScrollBottom = ({
  bottom = 0,
  onClick,
}: ButtonScrollBottomProps) => {
  return (
    <Button
      position="absolute"
      variant="unstyled"
      d="grid"
      placeItems="center"
      right="6%"
      bottom={30 + bottom + "px"}
      p="2"
      bg="#fff"
      boxShadow="0 1px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 2px 0 rgba(0, 0, 0, 0.12)"
      borderRadius="full"
      onClick={onClick}
    >
      <Badge
        position="absolute"
        top="-8px"
        left="50%"
        variant="solid"
        colorScheme="red"
        borderRadius="full"
      >
        +9
      </Badge>
      <FiChevronDown />
    </Button>
  );
};

export { ButtonScrollBottom };
