import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

interface MessageDateProps {
  children: ReactNode;
}

function MessageDate({ children }: MessageDateProps) {
  return (
    <Box
      borderRadius="sm"
      position="relative"
      boxShadow="sm"
      maxW="150px"
      bg="gray.200"
      color="gray.600"
      fontSize="sm"
      textTransform="uppercase"
      textAlign="center"
      mx="auto"
    >
      {children}
    </Box>
  );
}

export default MessageDate;