import { chakra, Flex, Box, Input } from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { FiSearch } from "react-icons/fi";
import { debounce } from "utils/debounce";

const CFiSeach = chakra(FiSearch);

interface ChatListHeaderProps {
  onSearch: (value: string) => void;
}

export default function ChatListHeader({ onSearch }: ChatListHeaderProps) {
  const debouncedOnChange = debounce((event: ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target?.value || "");
  }, 500);

  return (
    <Flex w="full">
      <Box
        position="sticky"
        d="flex"
        alignItems="center"
        h="64px"
        w="full"
        px="4"
      >
        <CFiSeach d="flex" position="absolute" left="8" color="gray.400" />
        <Input
          type="search"
          size="sm"
          pl="10"
          border="1px solid"
          borderColor="gray.400"
          borderRadius="md"
          placeholder="Search"
          color="gray.500"
          style={{
            caretColor: "gray.400",
          }}
          onChange={debouncedOnChange}
        />
      </Box>
    </Flex>
  );
}
