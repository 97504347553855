import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import emoji from "remark-emoji";

interface MessageMarkdownProps {
  children: string;
}

function MessageMarkdown({ children }: MessageMarkdownProps) {
  return (
    <ReactMarkdown
      remarkPlugins={[gfm, emoji]}
      allowedElements={["strong", "a", "code", "em", "p", "del", "u"]}
      linkTarget="_blank"
      unwrapDisallowed
      skipHtml
    >
      {children}
    </ReactMarkdown>
  );
}

export default MessageMarkdown;
