import {
  UIEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ChakraProvider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Input,
  FormControl,
  FormLabel,
  Stack,
} from "@chakra-ui/react";
import { useResizeDetector } from "react-resize-detector";
import { SubmitHandler, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { Client, client, xml } from "@xmpp/client";
import debug from "@xmpp/debug";
import { useCustomEvent, useListener } from "react-custom-events-hooks";
import "./style.css";

import ChatWindow from "components/ChatWindow";

import ChatList from "components/ChatList";
import ChatListItem from "components/ChatListItem";
import ChatListHeader from "components/ChatListHeader";
import ChatListContainer from "components/ChatListContainer";

import ChatScreen from "components/ChatScreen";
import ChatScreenHeader from "components/ChatScreenHeader";
import ChatScreenMessages from "components/ChatScreenMessages";
import Messages from "components/Messages";
import ChatScreenFooter from "components/ChatScreenFooter";

import EmptyStateScreen from "components/EmptyStateScreen";

import contacts from "./mocks/contacts";
import IMessage, { Content } from "types/message";
import { ButtonScrollBottom } from "components/ButtonScrollBottom";
import { debounce } from "utils/debounce";
import IContact from "types/contact";

enum CHAT_EVENT {
  ONLINE = "chat.online",
  OFFLINE = "chat.offline",
  ERROR = "chat.error",
  STANZA = "chat.stanza",
  AUTH = "chat.auth",
  RECEIVE_MESSAGE = "chat.receive_message",
  SEND_MESSAGE = "chat.send_message",
  GROUP_MESSAGE = "chat.group_message",
  STATUS = "chat.status",
  AUTHENTICATED = "chat.authenticated",
}

enum StanzaAttrChat {
  CHAT = "chat",
  GROUPCHAT = "groupchat",
}

const NS_CHATSTATES = "http://jabber.org/protocol/chatstates";

type FormValuesLogin = {
  username: string;
  password: string;
};

type FormValuesNewConversation = {
  username: string;
};

function App() {
  const [selected, setSelected] = useState<string>("");
  const [chatList, setChatList] = useState<IContact[]>(() => {
    const chat = localStorage.getItem("chatList");

    if (chat) {
      return JSON.parse(chat);
    }

    return [];
  });
  const [messageList, setMessageList] = useState<IMessage[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modalStartConversation = useDisclosure();
  const [showScrollButton, setShowScrollButton] = useState(false);
  const { height, ref: chatScreenFooterRef } = useResizeDetector();
  const chatScreenMessagesRef = useRef<HTMLDivElement>(null);
  const [xmppClient, setXmppClient] = useState<Client>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const formLoginRef = useRef<HTMLFormElement>(null);
  const [userCredentials, setUserCredentials] = useState<{
    username: string;
    password: string;
  } | null>(null);
  const userRef = useRef<{
    username: string;
    password: string;
  } | null>(null);

  const callReceiveMessage = useCustomEvent({
    eventName: "receiveMessage",
  });
  const receiveMessage = useCallback((e) => {
    const { message, send } = e.detail;

    setMessageList((prev) => [
      ...prev,
      {
        id: uuidv4(),
        timestamp: new Date(),
        contactId: userRef.current?.username || send.attrs.from,
        content: {
          type: "text/richtext",
          data: message,
          url: "",
        },
        sender: {
          type: "attendant",
          user: {
            id: uuidv4(),
            name: userRef.current?.username || send.attrs.from,
          },
        },
      },
    ]);
  }, []);
  useListener("receiveMessage", receiveMessage);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitted },
  } = useForm();
  const formStartConversation = useForm();

  const onSubmit: SubmitHandler<FormValuesLogin> = async (values) => {
    console.log(`values`, values);

    const xmpp = client({
      service: "ws://52.200.82.18:5443/ws",
      domain: "robot.marvin",
      username: values.username,
      password: values.password,
    });

    setXmppClient(xmpp);

    debug(xmpp, true);

    xmpp.on("status", (status) => {
      console.log(CHAT_EVENT.STATUS, status);
    });

    xmpp.on("online", async (address) => {
      console.log(CHAT_EVENT.ONLINE);
      console.log("online as", address.toString());

      // Makes itself available
      await xmpp.send(xml("presence"));
    });

    xmpp.on("offline", () => {
      console.log(CHAT_EVENT.OFFLINE);
    });

    xmpp.on("error", (eerr) => {
      console.log("error", eerr);
    });

    xmpp.on("stanza", (stanza) => {
      console.log(`stanza`, stanza);

      if (stanza.is("message")) {
        switch (stanza.attrs.type) {
          case StanzaAttrChat.CHAT: {
            const body = stanza.getChild("body");
            if (body) {
              var message = body.getText();

              setMessageList((prev) => [
                ...prev,
                {
                  id: uuidv4(),
                  timestamp: new Date(),
                  contactId: stanza.attrs.from,
                  content: {
                    type: "text/richtext",
                    data: message,
                    url: "",
                  },
                  sender: {
                    type: "attendant",
                    user: {
                      id: stanza.attrs.from,
                      name: stanza.attrs.from,
                    },
                  },
                },
              ]);
            }

            const chatstate = stanza.getChildByAttr("xmlns", NS_CHATSTATES);

            if (chatstate) {
              console.log(`chatstate`, chatstate);
              // TODO: emmit to chatEvent or socket.io
            }

            return;
          }

          default: {
            break;
          }
        }
        return;
      }
    });

    xmpp.on("send", (send) => {
      if (send.is("message")) {
        //getting the chat message
        switch (send.attrs.type) {
          case StanzaAttrChat.CHAT: {
            const body = send.getChild("body");
            if (body) {
              var message = body.getText();

              callReceiveMessage({ message, send });
            }

            return;
          }

          default: {
            break;
          }
        }
        return;
      }
    });

    const jid = await xmpp.start();
    setIsAuthenticated(true);
    console.log(jid);

    setUserCredentials(values);
    userRef.current = values;
    localStorage.setItem("user", JSON.stringify(values));
  };

  useEffect(() => {
    const userString = localStorage.getItem("user");

    if (userString) {
      const user = JSON.parse(userString);
      setValue("username", user.username);
      setValue("password", user.password);

      onOpen();

      setTimeout(() => {
        formLoginRef.current?.dispatchEvent(
          new Event("submit", { cancelable: true, bubbles: true })
        );
      }, 500);
    } else {
      onOpen();
    }
  }, [onOpen, setValue]);

  useEffect(() => {
    if (isAuthenticated) {
      console.log(`isAuthenticated`, isAuthenticated);
      onClose();
    }
  }, [isAuthenticated, onClose]);

  const onSubmitNewConversation: SubmitHandler<FormValuesNewConversation> = (
    values
  ) => {
    console.log(`values`, values);
    setChatList((prevState) => [
      ...prevState,
      {
        id: uuidv4(),
        avatar: "",
        clientId: values.username,
        isActive: false,
        lastActive: "",
        messages: [],
        name: values.username,
      },
    ]);
    modalStartConversation.onClose();
  };

  useEffect(() => {
    if (chatList) {
      localStorage.setItem("chatList", JSON.stringify(chatList));
    }
  }, [chatList]);

  const handleSelect = (chatId: string) => {
    setSelected(chatId);
  };

  const selectedChat = useMemo(
    () => chatList.find((chat) => chat.id === selected),
    [selected, chatList]
  );

  useEffect(() => {
    if (selected) {
      const contact = contacts.find((contact) => contact.id === selected);

      if (contact) {
        const { messages } = contact;
        setMessageList([...messages]);
      }
    }
  }, [selected]);

  const onSend = async (content: Content) => {
    const message = xml(
      "message",
      { type: "chat", to: selectedChat?.clientId },
      xml("body", undefined, content.data)
    );
    await xmppClient?.send(message);
  };

  const handleSearch = (searchName: string) => {
    setChatList(
      contacts.filter((chat) =>
        chat.name
          .toLocaleLowerCase()
          .trim()
          .includes(searchName.toLocaleLowerCase().trim())
      )
    );
  };

  const scrollToBottom = () => {
    chatScreenMessagesRef.current?.scrollTo({
      top: chatScreenMessagesRef.current?.scrollHeight,
      behavior: "smooth",
    });
  };

  //** Scrolling handler */
  function getScrollTopDistance(element: HTMLDivElement) {
    const { scrollTop } = element;

    return Math.abs(scrollTop);
  }

  const debouncedOnScroll = debounce((eventElem: HTMLDivElement) => {
    const distanceScrolledToTop = getScrollTopDistance(eventElem);
    const minScrollDistance = 100;

    const scrollingToTop = distanceScrolledToTop > minScrollDistance;

    setShowScrollButton(scrollingToTop);
  }, 100);

  function onScrollMessages(e: UIEvent<HTMLDivElement>) {
    if (!e.target) return;
    const eventElem = e.target as HTMLDivElement;

    debouncedOnScroll(eventElem);
  }
  //** End Scrolling handler */

  return (
    <ChakraProvider>
      <ChatWindow>
        <ChatListContainer>
          <ChatListHeader onSearch={handleSearch} />
          <ChatList>
            {chatList.map((chat) => {
              const lastMessage = chat.messages[chat.messages.length - 1];

              return (
                <ChatListItem
                  key={chat.id}
                  id={chat.id}
                  name={chat.name}
                  avatar={chat?.avatar}
                  onClick={handleSelect}
                  lastMessage={lastMessage}
                  isActive={chat.id === selected}
                />
              );
            })}
          </ChatList>
          <Button onClick={() => modalStartConversation.onOpen()}>
            Start conversation
          </Button>
        </ChatListContainer>
        <ChatScreen>
          {selected ? (
            <>
              <ChatScreenHeader
                name={selectedChat?.name}
                avatar={selectedChat?.avatar}
              />
              <ChatScreenMessages
                ref={chatScreenMessagesRef}
                startedConvesation={!!messageList.length}
                onScroll={onScrollMessages}
              >
                <Messages
                  userId={userCredentials?.username}
                  messages={messageList}
                />
              </ChatScreenMessages>
              {showScrollButton && (
                <ButtonScrollBottom bottom={height} onClick={scrollToBottom} />
              )}
              <ChatScreenFooter ref={chatScreenFooterRef} onSend={onSend} />
            </>
          ) : (
            <EmptyStateScreen />
          )}
        </ChatScreen>
      </ChatWindow>

      <Modal isOpen={isOpen} onClose={() => {}}>
        <ModalOverlay />
        <ModalContent>
          <form ref={formLoginRef} onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>Login</ModalHeader>
            <ModalBody>
              <Stack>
                <FormControl>
                  <FormLabel htmlFor="username">Username</FormLabel>
                  <Input
                    id="username"
                    type="text"
                    {...register("username")}
                    placeholder="name.surname"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <Input
                    id="password"
                    type="password"
                    {...register("password")}
                  />
                </FormControl>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button type="submit" isLoading={isSubmitted}>
                Login
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={modalStartConversation.isOpen}
        onClose={modalStartConversation.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <form
            onSubmit={formStartConversation.handleSubmit(
              onSubmitNewConversation
            )}
          >
            <ModalHeader>Start conversation with:</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack>
                <FormControl>
                  <FormLabel htmlFor="username">Username</FormLabel>
                  <Input
                    id="username"
                    type="text"
                    {...formStartConversation.register("username")}
                    placeholder="name.surname@domain.com"
                  />
                </FormControl>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button
                type="submit"
                isLoading={formStartConversation.formState.isSubmitted}
              >
                Start
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}

export default App;
