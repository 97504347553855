import { Flex } from "@chakra-ui/react";

interface IChatListContainerProps {
  children: React.ReactNode;
}

export default function ChatListContainer({ children }: IChatListContainerProps): JSX.Element {
  return (
    <Flex
      direction="column"
      minW="260px"
      maxW="350px"
      w="full"
      h="full"
      borderRight="1px solid"
      borderColor="gray.200"
    >
      {children}
    </Flex>
  );
}
