import { addMinutes } from 'date-fns';
import Contact from '../types/contact';

const contacts: Contact[] = [
  {
    id: '1',
    clientId: '19999999999',
    name: "Roberto",
    avatar: '',
    isActive: true,
    lastActive: 'today',
    messages: [
      {
        id: '1',
        contactId: '1',
        sender: {
          type: 'client',
          user: {
            id: '19999999999',
            name: 'Roberto'
          } 
        },
        timestamp: addMinutes(new Date(), 1),
        content: {
          type: 'text/richtext',
          data: 'Olá, tudo bem?',
          url: null
        }
      },
      {
        id: '2',
        contactId: '1',
        sender: {
          type: 'client',
          user: {
            id: '19999999999',
            name: 'Roberto'
          } 
        },
        timestamp: addMinutes(new Date(), 2),
        content: {
          type: 'text/richtext',
          data: 'Gostaria de uma informação.',
          url: null
        }
      },
      {
        id: '3',
        contactId: '1',
        sender: {
          type: 'attendant',
          user: {
            id: '9990',
            name: 'Robson'
          } 
        },
        timestamp: addMinutes(new Date(), 3),
        content: {
          type: 'text/richtext',
          data: 'Em que posso lhe ajudar?',
          url: null
        }
      },
      {
        id: '4',
        contactId: '1',
        sender: {
          type: 'attendant',
          user: {
            id: '9999',
            name: 'Lucas'
          } 
        },
        timestamp: addMinutes(new Date(), 4),
        content: {
          type: 'text/richtext',
          data: 'Como podemos lhe ajudar?',
          url: null
        }
      },
    ]
  },
  {
    id: '2',
    clientId: '19999999999',
    name: 'Roberto',
    avatar: '',
    isActive: true,
    lastActive: 'today',
    messages: [
      {
        id: '1',
        contactId: '1',
        sender: {
          type: 'client',
          user: {
            id: '19999999999',
            name: 'Roberto'
          } 
        },
        timestamp: addMinutes(new Date(), 5),
        content: {
          type: 'text/richtext',
          data: 'Olá, tudo bem?',
          url: null
        }
      },
      {
        id: '2',
        contactId: '1',
        sender: {
          type: 'attendant',
          user: {
            id: '777',
            name: 'Anny'
          } 
        },
        timestamp: addMinutes(new Date(), 6),
        content: {
          type: 'text/richtext',
          data: 'Seja bem vindo ao atendimento.',
          url: null
        }
      },
      {
        id: '3',
        contactId: '1',
        sender: {
          type: 'attendant',
          user: {
            id: '9990',
            name: 'Robson'
          } 
        },
        timestamp: addMinutes(new Date(), 7),
        content: {
          type: 'text/richtext',
          data: 'Em que posso lhe ajudar?',
          url: null
        }
      },
      {
        id: '4',
        contactId: '1',
        sender: {
          type: 'attendant',
          user: {
            id: '9999',
            name: 'Lucas'
          } 
        },
        timestamp: addMinutes(new Date(), 8),
        content: {
          type: 'text/richtext',
          data: 'Como podemos lhe ajudar?',
          url: null
        }
      },
    ]
  },
  {
    id: '3',
    clientId: '19999999999',
    name: 'Roberto',
    avatar: '',
    isActive: true,
    lastActive: 'today',
    messages: [
      {
        id: '1',
        contactId: '1',
        sender: {
          type: 'client',
          user: {
            id: '19999999999',
            name: 'Roberto'
          } 
        },
        timestamp: addMinutes(new Date(), 9),
        content: {
          type: 'text/richtext',
          data: 'Olá, tudo bem?',
          url: null
        }
      },
      {
        id: '2',
        contactId: '1',
        sender: {
          type: 'client',
          user: {
            id: '19999999999',
            name: 'Roberto'
          } 
        },
        timestamp: addMinutes(new Date(), 10),
        content: {
          type: 'text/richtext',
          data: 'Gostaria de uma informação.',
          url: null
        }
      },
      {
        id: '3',
        contactId: '1',
        sender: {
          type: 'attendant',
          user: {
            id: '9999',
            name: 'Lucas'
          } 
        },
        timestamp: addMinutes(new Date(), 11),
        content: {
          type: 'text/richtext',
          data: 'Em que posso lhe ajudar?',
          url: null
        }
      },
      {
        id: '4',
        contactId: '1',
        sender: {
          type: 'client',
          user: {
            id: '19999999999',
            name: 'Roberto'
          } 
        },
        timestamp: addMinutes(new Date(), 12),
        content: {
          type: 'text/richtext',
          data: 'Estou com problemas com a minha internet.',
          url: null
        }
      },
      {
        id: '5',
        contactId: '1',
        sender: {
          type: 'attendant',
          user: {
            id: '9999',
            name: 'Lucas'
          } 
        },
        timestamp: addMinutes(new Date(), 13),
        content: {
          type: 'text/richtext',
          data: 'Muito bem, você já desligou seu modem e aguardou por 10 segundos?',
          url: null
        }
      },
      {
        id: '6',
        contactId: '1',
        sender: {
          type: 'client',
          user: {
            id: '19999999999',
            name: 'Roberto'
          } 
        },
        timestamp: addMinutes(new Date(), 14),
        content: {
          type: 'text/richtext',
          data: 'Já sim.',
          url: null
        }
      },
      {
        id: '7',
        contactId: '1',
        sender: {
          type: 'attendant',
          user: {
            id: '9999',
            name: 'Lucas'
          } 
        },
        timestamp: addMinutes(new Date(), 15),
        content: {
          type: 'text/richtext',
          data: 'E resolveu o problema?',
          url: null
        }
      },
      {
        id: '8',
        contactId: '1',
        sender: {
          type: 'client',
          user: {
            id: '19999999999',
            name: 'Roberto'
          } 
        },
        timestamp: addMinutes(new Date(), 16),
        content: {
          type: 'text/richtext',
          data: 'Não.',
          url: null
        }
      },
      {
        id: '9',
        contactId: '1',
        sender: {
          type: 'attendant',
          user: {
            id: '9999',
            name: 'Lucas'
          } 
        },
        timestamp: addMinutes(new Date(), 17),
        content: {
          type: 'text/richtext',
          data: 'Tente novamente, por favor.',
          url: null
        }
      },
      {
        id: '10',
        contactId: '1',
        sender: {
          type: 'client',
          user: {
            id: '19999999999',
            name: 'Roberto'
          } 
        },
        timestamp: addMinutes(new Date(), 18),
        content: {
          type: 'text/richtext',
          data: 'OK, um minuto por gentileza.',
          url: null
        }
      },
      {
        id: '11',
        contactId: '1',
        sender: {
          type: 'client',
          user: {
            id: '19999999999',
            name: 'Roberto'
          } 
        },
        timestamp: addMinutes(new Date(), 19),
        content: {
          type: 'text/richtext',
          data: 'Realmente, não funcionou.',
          url: null
        }
      },
      {
        id: '12',
        contactId: '1',
        sender: {
          type: 'attendant',
          user: {
            id: '9999',
            name: 'Lucas'
          } 
        },
        timestamp: addMinutes(new Date(), 20),
        content: {
          type: 'text/richtext',
          data: 'Perfeito, vou solicitar um técnico para ir à sua residência. No dia 25/12 às 22:00 é um bom horário?',
          url: null
        }
      },
      {
        id: '13',
        contactId: '1',
        sender: {
          type: 'client',
          user: {
            id: '19999999999',
            name: 'Roberto'
          } 
        },
        timestamp: addMinutes(new Date(), 21),
        content: {
          type: 'text/richtext',
          data: 'Claro, assim ele já faz o trabalho e fica para a ceia.',
          url: null
        }
      },
      {
        id: '14',
        contactId: '1',
        sender: {
          type: 'attendant',
          user: {
            id: '9999',
            name: 'Lucas'
          } 
        },
        timestamp: addMinutes(new Date(), 22),
        content: {
          type: 'text/richtext',
          data: 'Ok, marcado então para o dia 25/12, às 22:00. Algo mais em que eu posso lhe ajudar?',
          url: null
        }
      },
      {
        id: '15',
        contactId: '1',
        sender: {
          type: 'client',
          user: {
            id: '19999999999',
            name: 'Roberto'
          } 
        },
        timestamp: addMinutes(new Date(), 23),
        content: {
          type: 'text/richtext',
          data: 'Só isso só.',
          url: null
        }
      },
      {
        id: '16',
        contactId: '1',
        sender: {
          type: 'attendant',
          user: {
            id: '9999',
            name: 'Lucas'
          } 
        },
        timestamp: addMinutes(new Date(), 24),
        content: {
          type: 'text/richtext',
          data: 'Agradecemos o seu contato. Boas festas!',
          url: null
        }
      },
    ]
  }
]

export default contacts;