import { Box, Text } from "@chakra-ui/react";
// import MessageImage from "components/Messages/components/MessageImage";
import { format, formatRelative, isSameDay } from "date-fns";
import { Fragment } from "react";
import IMessage from "types/message";
// import { isImageFile } from "utils/mediaFile";

import MessageBox from "./components/MessageBox";
import MessageDate from "./components/MessageDate";
import MessageMarkdown from "./components/MessageMarkdown";

// interface IFile {
//   id: string;
//   src: string;
//   name: string;
//   type: string;
// }

interface MessagesProps {
  messages: IMessage[];
  userId?: string; 
}

function Messages({ userId, messages }: MessagesProps) {
  /*
  const renderMessageFile = (file: IFile) => {
    if (isImageFile(file)) {
      return <MessageImage src={file.src} alt={file.name} />;
    }
  };
  */
  console.log(`messages`, messages)
  return (
    <Box>
      {messages.map((message, index) => {
        const lastMessage = messages[index - 1];

        const lastMessageIsNotSameDay = !isSameDay(
          message.timestamp,
          lastMessage?.timestamp
        );
        const showDate = lastMessageIsNotSameDay;

        const formattedMessageDate = formatRelative(
          message.timestamp,
          new Date()
        );
        const formattedMessageHour = format(message.timestamp, "HH:mm");

        return (
          <Fragment key={message.id}>
            {showDate && <MessageDate>{formattedMessageDate}</MessageDate>}
            <MessageBox
              type={message.sender.type}
              isMine={message.sender.user.name === userId}
              sender={message.sender.user.name}
            >
              {message.content.type === "text/richtext" ? (
                <MessageMarkdown>{message.content.data}</MessageMarkdown>
              ) : (
                <MessageMarkdown>Vazio</MessageMarkdown>
              )}

              <Text
                as="time"
                d="block"
                fontSize="11px"
                color="gray.500"
                textAlign="right"
              >
                {formattedMessageHour}

                {/*message?.seen && (
                  <Text as="i" fontSize="xs" color="gray.500">
                    {" - visto"}
                  </Text>
                )*/}
              </Text>
            </MessageBox>
          </Fragment>
        );
      })}
    </Box>
  );
}

export default Messages;
