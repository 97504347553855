import { Box } from "@chakra-ui/react";
import ContainerScroll from "components/ChatScreenMessages/components/ContainerScroll";

import { forwardRef, ReactNode, UIEvent } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

interface ChatScreenMessagesProps {
  children: ReactNode;
  startedConvesation?: boolean;
  onScroll: (el: UIEvent<HTMLDivElement>) => void;
}

const ChatScreenMessages = forwardRef<HTMLDivElement, ChatScreenMessagesProps>(
  ({ children, startedConvesation, onScroll }, ref) => {
    return (
      <ContainerScroll ref={ref} onScroll={onScroll}>
        {startedConvesation ? (
          <InfiniteScroll
            scrollableTarget={`scrollable_room_messages`}
            dataLength={0}
            next={() => {}}
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              overflowX: "hidden",
            }} // To put endMessage and loader to the top.
            scrollThreshold="300px"
            inverse
            hasMore
            loader={
              <Box d="none" placeItems="center">
                Loading...
              </Box>
            }
            initialScrollY={0}
          >
            <Box p="0 5px 5px">{children}</Box>
          </InfiniteScroll>
        ) : (
          <Box mb="8" fontSize="sm" fontStyle="italic" textAlign="center">
            Let's start the conversation!
          </Box>
        )}
      </ContainerScroll>
    );
  }
);

export default ChatScreenMessages;
