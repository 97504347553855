import { Flex } from "@chakra-ui/react";

interface IChatScreenProps {
  children: React.ReactNode;
}

export default function ChatScreen({
  children,
}: IChatScreenProps): JSX.Element {
  return (
    <Flex
      direction="column"
      flex="1"
      w="full"
      h="full"
      position="relative"
      overflow="hidden"
      borderRightRadius="md"
    >
      {children}
    </Flex>
  );
}
