import { Flex } from "@chakra-ui/react";

interface IChatWindowProps {
  children: React.ReactNode;
}

export default function ChatWindow({
  children,
}: IChatWindowProps): JSX.Element {
  return (
    <Flex w="full" h="full" p="4" bg="gray.400">
      <Flex
        w="full"
        border="1px solid"
        bg="white"
        borderColor="gray.400"
        borderRadius="md"
      >
        {children}
      </Flex>
    </Flex>
  );
}
