import { Flex } from "@chakra-ui/react";
import { forwardRef, UIEvent } from "react";
import cnversationBg from "assets/bg_conversation.png";

interface ContainerScrollProps {
  children: React.ReactNode;
  onScroll: (el: UIEvent<HTMLDivElement>) => void;
}

const ContainerScroll = forwardRef<HTMLDivElement, ContainerScrollProps>(
  ({ children, onScroll }, ref) => {
    return (
      <Flex
        ref={ref}
        position="relative"
        direction="column-reverse"
        flex="1"
        overflowY="auto"
        backgroundImage={cnversationBg}
        mt="64px"
        onScroll={onScroll}
      >
        {children}
      </Flex>
    );
  }
);

export default ContainerScroll;
