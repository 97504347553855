import { Flex, Avatar, Box, Text, Badge } from "@chakra-ui/react";
import { formatDistanceToNow } from "date-fns";
import { useEffect, useRef, useState } from "react";
import IMessage from "types/message";

interface IChatListItemProps {
  isActive?: boolean;
  id: string;
  name: string;
  avatar: string;
  lastMessage?: IMessage;
  onClick: (id: string) => void;
  newMessagesCount?: number;
}

function ChatListItem({
  onClick,
  isActive,
  id,
  name,
  avatar,
  lastMessage,
  newMessagesCount,
}: IChatListItemProps) {
  const [lastMessageDate, setLastMessageDate] = useState<string>();
  const intervalId = useRef<number>();

  useEffect(() => {
    intervalId.current = setInterval(() => {
      if (lastMessage?.timestamp) {
        setLastMessageDate(formatDistanceToNow(lastMessage?.timestamp));
      }
    }, 500) as unknown as number;

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [lastMessage?.timestamp]);

  return (
    <Box
      d="flex"
      flex="1 1 100%"
      px="4"
      minH="72px"
      maxH="72px"
      position="relative"
      borderBottom="1px solid"
      borderColor="gray.100"
      transition="background-color 0.2s"
      cursor="pointer"
      userSelect="none"
      bg={isActive ? "gray.200" : "white"}
      _hover={{
        backgroundColor: "gray.100",
        color: "gray.700",
      }}
      onClick={() => onClick(id)}
    >
      <Flex flex="1" alignItems="center" w="full">
        <Avatar mr="4" width="49px" height="49px" name={name} src={avatar} />

        <Box
          w="full"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Flex alignItems="center">
            <Text
              w="full"
              flex="1"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              fontWeight="semibold"
              color="gray.600"
            >
              {name}
            </Text>
            {lastMessage?.timestamp && (
              <Text ml="2" fontSize="sm" color="gray.500">
                há {lastMessageDate}
              </Text>
            )}
          </Flex>
          <Flex alignItems="center">
            <Text
              w="full"
              fontSize="xs"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              color="gray.500"
            >
              {lastMessage?.content?.data}
            </Text>
            {newMessagesCount && (
              <Badge borderRadius="full" bg="#06a041" color="white">
                {newMessagesCount > 9 ? "+9" : newMessagesCount}
              </Badge>
            )}
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}

export default ChatListItem;
