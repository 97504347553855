import { Box, Stack, IconButton, Textarea, chakra } from "@chakra-ui/react";
import {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  useRef,
  useState,
} from "react";
import { FiPaperclip } from "react-icons/fi";
import { MdSend } from "react-icons/md";
import TextareaAutosize from "react-textarea-autosize";

import { Content } from "types/message";
const CFiPaperclip = chakra(FiPaperclip);
const CMdSend = chakra(MdSend);

interface ChatScreenFooterProps {
  onSend: (content: Content) => void;
}

const ChatScreenFooter = forwardRef<HTMLDivElement, ChatScreenFooterProps>(
  ({ onSend }, ref) => {
    const [value, setValue] = useState("");
    const inptFileRef = useRef<HTMLInputElement>(null);

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);
    };

    const handleSubmit = () => {
      onSend({
        type: "text/richtext",
        data: value,
        url: null,
      });
      setValue("");
    };

    const onEnterPress = (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === "Enter" && event.shiftKey === false) {
        event.preventDefault();
        handleSubmit();
      }
    };

    const selectFile = () => {
      if (inptFileRef.current) {
        inptFileRef.current.click();
      }
    };

    const handleChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.item(0);
      if (file) {
        onSend({
          // TODO: change type to "file"
          // TODO: upload file
          type: "text/richtext",
          data: file.name,
          url: URL.createObjectURL(file),
        });
      }
    };

    return (
      <Box ref={ref} w="full" bg="blackAlpha.100" py="2" px="3">
        <input
          ref={inptFileRef}
          type="file"
          onChange={handleChangeFile}
          hidden
        />
        <Stack direction="row" alignItems="center">
          <IconButton
            variant="unstyled"
            d="grid"
            placeItems="center"
            size="sm"
            aria-label="Send message"
            color="blue.500"
            icon={<CFiPaperclip w="18px" h="18px" />}
            _focus={{
              outline: "none",
            }}
            _hover={{
              color: "blue.600",
            }}
            onClick={selectFile}
          />
          <Textarea
            as={TextareaAutosize}
            placeholder="Type message..."
            resize="none"
            autoComplete="off"
            overflowY="hidden"
            rows={1}
            maxRows={5}
            p="2"
            bg="white"
            _focus={{
              outline: "none",
            }}
            onKeyDown={onEnterPress}
            onChange={handleChange}
            value={value}
          />
          <IconButton
            variant="unstyled"
            d="grid"
            placeItems="center"
            size="sm"
            aria-label="Send message"
            color="blue.500"
            icon={<CMdSend w="26px" h="26px" />}
            _focus={{
              outline: "none",
            }}
            _hover={{
              color: "blue.600",
            }}
            onClick={handleSubmit}
          />
        </Stack>
      </Box>
    );
  }
);

export default ChatScreenFooter;
